import React from "react"

export default function Footer () {
    return (
        <footer className="keni-footer_wrap">
            <div className="keni-copyright_wrap">
                <div className="keni-copyright">
                      <small>© 2021 株式会社わくわくスタディワールド</small>
                </div>
            </div>
        </footer>
    )
}