import React from "react"

import Header from "../components/header"
import GlobalNavi from "../components/globalnavi"
import Footer from "../components/footer"

export default function Layout ({ children }) {
    return (
        <div className="col1">
            <Header />
            <GlobalNavi />

            { children }

            <Footer />
        </div>
    )
}