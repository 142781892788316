import React from "react"

export default function Header() {
    return (
        <div>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <title>株式会社わくわくスタディワールド</title>
            <meta name="description" content="株式会社わくわくスタディワールドのページです。" /><link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
            <link href="https://fonts.googleapis.com/css?family=Oswald" rel="stylesheet" />
            <div id="top" className="keni-container">
                <div className="keni-header_wrap">
                    <div className="keni-header_outer">
                        <header className="keni-header keni-header_col2">
                            <div className="keni-header_inner">
                                <h1 className="site-title">
                                    <a href="/"><img src="/images/logo-wakuwaku.gif" alt="株式会社わくわくスタディワールド" /></a>
                                </h1>
                                <div className="keni-header_cont">
                                    {/* <div className="show-pc">
                                        <a href="/contact/" class="btn btn_style02"><span class="icon_mail"></span>お問い合わせ</a>
                                    </div>
                                    <div className="header-mail show-sp">
                                        <a href="/contact/" class="btn_header"><span class="icon_mail"></span></a>
                                    </div> */}
                                </div>

                            </div>
                        </header>
                    </div>
                </div>
            </div>
        </div>
    )
}