import React from "react"

class GrobalNavi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: true};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }
  render() {
    return (
      <div>
        <div id="click-space" style={this.state.isToggleOn ? {width: "0%", height: "0%"} : {width: "100%", height: "100%"}}></div>
        <div className="keni-gnav_wrap">
          <div className="keni-gnav_outer">
            <nav className="keni-gnav">
              <div className="keni-gnav_inner" style={this.state.isToggleOn ? {display: "none"} : {display: "block"}}>
                <ul className="keni-gnav_cont">
                  <li><a href="/"><span className="icon_home" /><span className="show-sp02"> HOME</span></a></li>
                  <li><a href="/about/">会社概要</a></li>
                  <li><a href="/book/">執筆書籍</a></li>
                  <li><a href="/seminar/">セミナー</a></li>
                  <li><a href="https://studyworld.thebase.in/">わく☆すたショップ</a></li>
                  <li><a href="/blog/">わく☆すたブログ</a></li>
                </ul>
              </div>
              <div className="keni-gnav_btn_wrap">
                <div className="keni-gnav_btn" onClick={this.handleClick}>
                  <span className={this.state.isToggleOn ? "keni-gnav_btn_icon-open" : "keni-gnav_btn_icon-close"} />
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    )
  }
}

export default GrobalNavi